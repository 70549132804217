.about-section {
    padding-top: 2rem;
    display: flex;
}
.contact-section-left {
    min-height: 100%;
    padding-top: 0 !important;
    padding-bottom: 0.5 !important;
}
.verif-span {
    color: #40b3ac;
    font-weight: 600;
}

.contact-section-left-mainTxt div:nth-child(1) {
    font-size: 1.4vw !important;
    /* padding: 0; */
    font-weight: 400;
    line-height: 135%;
}
.paragraph-two {
    font-size: 1.4vw !important;
    margin-top: 0.8em !important;
    font-weight: 400 !important;
    line-height: 135% !important;
}

.photo-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 7px 7px;
    grid-auto-flow: row;
    grid-template-areas:
        "photo1 photo4"
        "photo5 photo4"
        "photo3 photo2";
    color: white;
    padding: 1em;
    border: 1px white solid;
    max-height: 60%;
    max-width: 70%;
}

.photo-grid img {
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.photo1 {
    grid-area: photo1;
    background-color: red;
    background: url("../../assets/imgs/team/team-1.jpg") center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.photo2 {
    grid-area: photo2;
    background-color: blue;
    background: url("../../assets/imgs/team/team-2.jpg") center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.photo3 {
    grid-area: photo3;
    background-color: gray;
    background: url("../../assets/imgs/team/team-3.jpg") center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.photo5 {
    grid-area: photo5;
    background-color: green;
    background: url("../../assets/imgs/team/team-5.jpg") center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.photo4 {
    grid-area: photo4;
    background-color: black;
    background: url("../../assets/imgs/team/team-4.jpg") center center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 842px) {
    .about-section {
        padding: 1rem;
        display: flex;
        flex-direction: column;
    }
    .contact-section-left {
    }
    .photo-grid {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 7px 7px;
        grid-auto-flow: row;
        grid-template-areas:
            "photo1 photo4"
            "photo5 photo4"
            "photo3 photo2";
        color: white;
        padding: 1em;
        border: 1px white solid;
        max-width: 60%;
        margin-left: 2em;
    }
    .contact-section-left-mainTxt div:nth-child(1) {
        font-size: 2.5vw !important;
        /* padding: 0; */
        font-weight: 300;
        line-height: 150%;
    }
    .paragraph-two {
        font-size: 2.5vw !important;
        margin-top: 0.8em !important;
        font-weight: 300 !important;
        line-height: 135% !important;
    }
}

@media only screen and (max-width: 600px) {
    .photo-grid {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr fr fr;
        gap: 7px 7px;
        grid-auto-flow: row;
        grid-template-areas:
            "photo4"
            "photo2"
            "";
        color: white;
        padding: 1em;
        border: 1px white solid;
        max-width: 70%;
        height: auto;
        margin-left: 2em;
    }
}
