.contact-section{
    max-width: 100%;
    font-size: 1vw;
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr; 
    gap: 0px 54px; 
    margin-top: 3em;
   
}
.contact-section-left{
  padding: 2rem;
}
.contact-section-right{
  padding: 2rem;

}
.contact-section-text{
    /* color: white ;
    font-size: 5rem; */
    
}
.contact-section-left div:nth-child(1){
    color: white ;
    font-size: 3vw;
    margin: 0;
    padding: 0;
}
.contact-section-left div:nth-child(2){
    color: white ;
    font-size: 4vw;
    font-weight: 600;
    line-height: 130%;
}
.contact-section-left div:nth-child(3){
    color: white ;
    font-size: 1.8vw;
    width: 80%;
   
}

@media only screen and (max-width: 842px){
    .contact-section{
        max-width: 100%;
        font-size: 1vw;
        display: grid; 
        grid-template-columns: 1fr ; 
        grid-template-rows: 2fr; 
        gap: 0px 54px; 
        margin-top: 3em;
    }
    .contact-section-left div:nth-child(3){
        color: white ;
        font-size: 2.6vw;
        width: 80%;
       
    }
   
}