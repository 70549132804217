.form{
    color: white;
    max-width: 100%;
    margin: 0 auto;
    font-size: 1vw;
}
form .form-control:focus{
    border-color: rgb(205,212,218);
    box-shadow: none;
  }

button{
    margin-top: 1rem!important;
  }
button:hover, button:focus, button:active{
    background-color: #40B3AC!important;
    border: 1px #40B3AC!important;
    color: white!important;
    outline: none!important;
    box-shadow: none!important;
  }
  @media only screen and (max-width: 842px){
    .form{
    
      font-size: 3vw;
  }
  button{
    margin-top: 0;
    background-color: #40B3AC!important;
    border: 1px #40B3AC!important;
    color: white!important;
    outline: none!important;
  }
button:hover, button:focus, button:active{
    background-color: #092e2c!important;
    border: 1px #092e2c!important;
    color: white!important;
    outline: none!important;
    box-shadow: none!important;
  }
  }