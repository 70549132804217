.form {
    color: white;
    max-width: 100%;
    margin: 0 auto;
    font-size: 1vw;
}

form .formControl:focus {
    border-color: rgb(205, 212, 218);
    box-shadow: none;
}

button {
    margin-top: 1rem !important;
}
button:hover,
button:focus,
button:active {
    background-color: #40b3ac !important;
    border: 1px #40b3ac !important;
    color: white !important;
    outline: none !important;
    box-shadow: none !important;
}
@media only screen and (max-width: 842px) {
    .form {
        font-size: 3vw;
    }
    button {
        margin-top: 0;
        background-color: #40b3ac !important;
        border: 1px #40b3ac !important;
        color: white !important;
        outline: none !important;
    }
    button:hover,
    button:focus,
    button:active {
        background-color: #092e2c !important;
        border: 1px #092e2c !important;
        color: white !important;
        outline: none !important;
        box-shadow: none !important;
    }
}

.btn-round {
    margin-left: 5px;
    border-radius: 50% !important;
    width: 2em;
    height: 2em;
}

.calculator-input {
    color: #40b3ac !important;
    font-size: 1.2em !important;
    padding-left: 0 !important;
}

.line-table-calculator {
    color: teal;
    height: 5px !important;
}

.title-calculator{
    color: #40b3ac;
    background-color: rgba(27, 27, 27, 0.61);
    padding: 10px 0 10px 0;
}
.title-calculator-first{
    font-size: 1.2em;
}
.title-calculator-footer{
    padding-top: 10px;
    text-align: center;
    font-size: 25px;
}
.total-cost-number{
    font-size: 1.2em;
}