.header-animated {
    /* overflow-wrap:break-word;
    margin: 0;
    padding:0;     */
}
.text-first {
    color: white;
    font-size: 5.5vw;
    font-weight: 600;
    max-width: 80%;
    max-height: 80%;
    margin: 0 auto;
    padding: 0.6em 0;
    text-align: center;
    transform: scale(0.94);
    animation: scale 2s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

/* .text-second {
    background-color: #f7f7f7;
    padding: 1.3em ; 
    color: black;
    font-size:3.2vw;
    min-height: 30rem;
    margin: 0 auto;
    text-align: center; 
  } */

@keyframes scale {
    100% {
        transform: scale(1);
    }
}

.text-first-span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
}

.text-first-span:nth-child(1) {
    animation: fade-in 1s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.1em;
    /* padding: 0 2em; */
}

.text-first-span:nth-child(2) {
    animation: fade-in 1s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.2em;
}

.text-first-span:nth-child(3) {
    animation: fade-in 1s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.2em;
    /* padding: 0 2.3rem; */
}

.text-first-span:nth-child(4) {
    animation: fade-in 1s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.2em;
}

/* .text-first-span:nth-child(5) {
    animation: fade-in 1s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.3rem;
  } */
.text-first-span:nth-child(6) {
    animation: fade-in 1s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.2em;
}
.text-first-span:nth-child(7) {
    padding: 0 !important;
    margin: 0 !important;
}
.text-first-span:nth-child(8) {
    animation: fade-in 1s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
    /* margin: 0!important;
    padding: 0!important; */
    font-size: 0.5em !important;
    font-weight: 300 !important;
    /* position: relative;
    bottom: 0.5em; */
}
.text-first-span:nth-child(8) a {
    text-decoration: none;
    color: rgb(167, 163, 163);
}
.text-first-span:nth-child(8) a:hover {
    color: #40b3ac;
}

/* .text-second-span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
   
  } */

/* .text-second-span:nth-child(1) {
    animation: fade-in 1.2s 2.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.3rem;
  }
  
  .text-second-span:nth-child(2) {
    animation: fade-in 1.8s 2.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.3rem;
    font-weight: bolder;
  }
  
  .text-second-span:nth-child(3) {
    animation: fade-in 1.2s 3.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.3rem 2rem 0.3rem;
  } */

/* button{
    width: 15rem;
    margin-top: 2.5rem!important;
    font-size:x-large;
    color: #40B3AC;
    outline: #40B3AC;
    }
    button:hover{
      background-color: #40B3AC;
    }
    .employee-class{
    font-weight: 600;
    } */

@keyframes fade-in {
    100% {
        opacity: 1;
        filter: blur(0);
    }
}

/* @media only screen and (max-width: 992px) {
    .text-first {
        margin-top: 3rem;
        margin-bottom: 2rem;
        padding: 0.1rem 0.1rem;
        width: 90%;
        font-size:3.5rem;

       
      }
      .text-second {
        padding: 0.1rem 0.1rem; 
        width: 90%;
        font-size:4rem;
     
      }
  }

  @media only screen and (max-width: 768px) {
    .text-first {
        margin-top: 4.5rem;
        margin-bottom: 3rem;
        padding: 0.1rem 0.1rem;
        width: 90%;
        font-size:1.5rem;

       
      }
    .text-second {
        padding: 0.1rem 0.1rem; 
        width: 90%;
        font-size:2.1rem;
     
      }
  } */
.header-container {
    margin-top: 30px;
    color: #3ab0ac;
    background-color: white;
}
.title-header {
    font-size: 60px;
    text-align: center;
}
.link-header {
    outline: none;
    color: #3ab0ac;
    text-align: center;
    display: block;
    margin-bottom: 10px;
}
.link-header:hover {
    color: #339996;
}
.header-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-width: 500px;
}
.header-img-container{
    margin: 0 auto;
}
