.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    flex-direction: column;
    height: 8rem;
}
.verif-logo {
    max-width: 8rem;
    margin-top: 2rem;
}
.verif-logo:hover {
    cursor: pointer;
}
.navLink {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    color: azure;
    font-size: 18px;
    gap: 1rem;
}
.navLink:hover {
    cursor: pointer;
    color: #fad700 !important;
}
.link {
    text-decoration: none;
    color: rgb(254, 254, 254);
}
.link:hover {
    text-decoration: none;
    color: rgb(254, 254, 254);
}
.spanLang {
    text-decoration: none;
    cursor: pointer;
    color: rgb(254, 254, 254);
}
.spanLang:hover {
    text-decoration: none;
    color: rgb(192, 192, 192);
}
.linkActive {
    color: #fad700;
}
