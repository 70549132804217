.footer-section{
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 7rem;
    background-color: #40B3AC;
    /* overflow: hidden; */
}

.footer-left-part  {
    /* display: flex;
    justify-content: flex-start;
    align-items: center; */
    /* padding-left: 1rem;
    padding-right: 1rem; */
    /* font-size: 1.4rem;
    color: white; */
    /* margin-left: 15em; */
  
}
.footer-left-part span {

    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.3vw;
    color: white;
  
}
.footer-left-part span:hover {
    color: rgb(250,215,0);
    font-weight: bold;
    cursor: pointer;
}
.footer-right-part{
    /* display: flex;
    justify-content: end; */
    /* margin-right: 15em; */
    min-height: 100%;
}
.footer-right-part a {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.7vw;
    color: white;
    text-decoration: none;
}
.footer-right-part a:hover {
    color: rgb(250,215,0);
    font-weight: bold;
    cursor: pointer;
}

@media only screen and (max-width: 842px){
    .footer-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
       
    }
    .footer-left-part span {
        font-size: 3.3vw; 
    }
    .footer-right-part a {
        font-size: 4vw;
    }
    
}