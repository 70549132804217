@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap);
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap'); */
 body {
  margin: 0;
  padding: 0;
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}  */

* {
    font-family: "Poppins", sans-serif;
}
.bg {
    background-image: url(/static/media/nav-bg.bb83e2e2.png);
    min-height: 100vh;
}

.Navbar_navbar__3F0zt {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    flex-direction: column;
    height: 8rem;
}
.Navbar_verif-logo__1zYds {
    max-width: 8rem;
    margin-top: 2rem;
}
.Navbar_verif-logo__1zYds:hover {
    cursor: pointer;
}
.Navbar_navLink__1oaty {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    color: azure;
    font-size: 18px;
    grid-gap: 1rem;
    gap: 1rem;
}
.Navbar_navLink__1oaty:hover {
    cursor: pointer;
    color: #fad700 !important;
}
.Navbar_link__2jvAc {
    text-decoration: none;
    color: rgb(254, 254, 254);
}
.Navbar_link__2jvAc:hover {
    text-decoration: none;
    color: rgb(254, 254, 254);
}
.Navbar_spanLang__2s0dI {
    text-decoration: none;
    cursor: pointer;
    color: rgb(254, 254, 254);
}
.Navbar_spanLang__2s0dI:hover {
    text-decoration: none;
    color: rgb(192, 192, 192);
}
.Navbar_linkActive__2BUcl {
    color: #fad700;
}

.header-animated {
    /* overflow-wrap:break-word;
    margin: 0;
    padding:0;     */
}
.text-first {
    color: white;
    font-size: 5.5vw;
    font-weight: 600;
    max-width: 80%;
    max-height: 80%;
    margin: 0 auto;
    padding: 0.6em 0;
    text-align: center;
    -webkit-transform: scale(0.94);
            transform: scale(0.94);
    -webkit-animation: scale 2s forwards cubic-bezier(0.5, 1, 0.89, 1);
            animation: scale 2s forwards cubic-bezier(0.5, 1, 0.89, 1);
}

/* .text-second {
    background-color: #f7f7f7;
    padding: 1.3em ; 
    color: black;
    font-size:3.2vw;
    min-height: 30rem;
    margin: 0 auto;
    text-align: center; 
  } */

@-webkit-keyframes scale {
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes scale {
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.text-first-span {
    display: inline-block;
    opacity: 0;
    -webkit-filter: blur(4px);
            filter: blur(4px);
}

.text-first-span:nth-child(1) {
    -webkit-animation: fade-in 1s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
            animation: fade-in 1s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.1em;
    /* padding: 0 2em; */
}

.text-first-span:nth-child(2) {
    -webkit-animation: fade-in 1s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
            animation: fade-in 1s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.2em;
}

.text-first-span:nth-child(3) {
    -webkit-animation: fade-in 1s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
            animation: fade-in 1s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.2em;
    /* padding: 0 2.3rem; */
}

.text-first-span:nth-child(4) {
    -webkit-animation: fade-in 1s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
            animation: fade-in 1s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.2em;
}

/* .text-first-span:nth-child(5) {
    animation: fade-in 1s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.3rem;
  } */
.text-first-span:nth-child(6) {
    -webkit-animation: fade-in 1s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
            animation: fade-in 1s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.2em;
}
.text-first-span:nth-child(7) {
    padding: 0 !important;
    margin: 0 !important;
}
.text-first-span:nth-child(8) {
    -webkit-animation: fade-in 1s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
            animation: fade-in 1s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
    /* margin: 0!important;
    padding: 0!important; */
    font-size: 0.5em !important;
    font-weight: 300 !important;
    /* position: relative;
    bottom: 0.5em; */
}
.text-first-span:nth-child(8) a {
    text-decoration: none;
    color: rgb(167, 163, 163);
}
.text-first-span:nth-child(8) a:hover {
    color: #40b3ac;
}

/* .text-second-span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
   
  } */

/* .text-second-span:nth-child(1) {
    animation: fade-in 1.2s 2.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.3rem;
  }
  
  .text-second-span:nth-child(2) {
    animation: fade-in 1.8s 2.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.3rem;
    font-weight: bolder;
  }
  
  .text-second-span:nth-child(3) {
    animation: fade-in 1.2s 3.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
    margin: 0 0.3rem 2rem 0.3rem;
  } */

/* button{
    width: 15rem;
    margin-top: 2.5rem!important;
    font-size:x-large;
    color: #40B3AC;
    outline: #40B3AC;
    }
    button:hover{
      background-color: #40B3AC;
    }
    .employee-class{
    font-weight: 600;
    } */

@-webkit-keyframes fade-in {
    100% {
        opacity: 1;
        -webkit-filter: blur(0);
                filter: blur(0);
    }
}

@keyframes fade-in {
    100% {
        opacity: 1;
        -webkit-filter: blur(0);
                filter: blur(0);
    }
}

/* @media only screen and (max-width: 992px) {
    .text-first {
        margin-top: 3rem;
        margin-bottom: 2rem;
        padding: 0.1rem 0.1rem;
        width: 90%;
        font-size:3.5rem;

       
      }
      .text-second {
        padding: 0.1rem 0.1rem; 
        width: 90%;
        font-size:4rem;
     
      }
  }

  @media only screen and (max-width: 768px) {
    .text-first {
        margin-top: 4.5rem;
        margin-bottom: 3rem;
        padding: 0.1rem 0.1rem;
        width: 90%;
        font-size:1.5rem;

       
      }
    .text-second {
        padding: 0.1rem 0.1rem; 
        width: 90%;
        font-size:2.1rem;
     
      }
  } */
.header-container {
    margin-top: 30px;
    color: #3ab0ac;
    background-color: white;
}
.title-header {
    font-size: 60px;
    text-align: center;
}
.link-header {
    outline: none;
    color: #3ab0ac;
    text-align: center;
    display: block;
    margin-bottom: 10px;
}
.link-header:hover {
    color: #339996;
}
.header-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-width: 500px;
}
.header-img-container{
    margin: 0 auto;
}

.aiSection-title-section{
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1000%;
    padding: 2em;
    margin: 0 auto;
    font-size: 1vw;
}
.aiSection-title-section img{
  max-width: 5rem;
  margin-right: 0.5em;
}

.aiSection-section{
    min-height: 40rem;
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
}
.aiSection-section img{
   max-width:25%;
   margin: 1rem;
   border-radius: 10px;
   /* border: solid 1px rgb(231, 229, 229); */
   cursor: zoom-in!important;
   box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.img-cursor{
  cursor: zoom-in;
}

@media only screen and (max-width: 842px){
  .aiSection-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.aiSection-section img{
  max-width:60%;
  margin: 1rem;
  border-radius: 10px;
  cursor: zoom-in!important;
}
}
.BuySection {
    min-height: 35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}
.BuySection img {
    max-width: 25%;
    padding-bottom: 3rem;
    padding-left: 3rem;
}
.BuySection ul li {
    list-style-type: circle;
    font-size: 2vw;
    padding: 0.5rem;
}
.leftPart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 5rem;
}
.leftPart button {
    width: 10rem;
    margin: 1rem 2rem;
    font-size: 1.4em;
    color: rgb(40, 135, 83);
    outline: rgb(40, 135, 83);
}
.leftPart button:hover {
    background-color: #40b3ac;
}

@media only screen and (max-width: 842px) {
    .BuySection {
        min-height: 25rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .BuySection img {
        display: none;
    }
    .BuySection ul li {
        font-size: 4vw;
    }
    .leftPart {
        margin-right: 0;
    }
}


.supporter-section{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15rem;
    background-color: #f7f7f7;
    overflow: hidden;
}
.supportItem img{
    width: 9rem;
    margin: 3rem;
}
.supporter-title-section{
    padding: 1rem;
    margin: 0 auto;
    color: #afaaaa;
    background-color: #ffffff;
}
.footer-section{
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-height: 7rem;
    background-color: #40B3AC;
    /* overflow: hidden; */
}

.footer-left-part  {
    /* display: flex;
    justify-content: flex-start;
    align-items: center; */
    /* padding-left: 1rem;
    padding-right: 1rem; */
    /* font-size: 1.4rem;
    color: white; */
    /* margin-left: 15em; */
  
}
.footer-left-part span {

    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.3vw;
    color: white;
  
}
.footer-left-part span:hover {
    color: rgb(250,215,0);
    font-weight: bold;
    cursor: pointer;
}
.footer-right-part{
    /* display: flex;
    justify-content: end; */
    /* margin-right: 15em; */
    min-height: 100%;
}
.footer-right-part a {
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1.7vw;
    color: white;
    text-decoration: none;
}
.footer-right-part a:hover {
    color: rgb(250,215,0);
    font-weight: bold;
    cursor: pointer;
}

@media only screen and (max-width: 842px){
    .footer-section{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
       
    }
    .footer-left-part span {
        font-size: 3.3vw; 
    }
    .footer-right-part a {
        font-size: 4vw;
    }
    
}
.text-second {
    background-color: #f7f7f7;
    padding: 1.3em;
    color: black;
    font-size: 3.2vw;
    /* width: 100%; */
    min-height: 8em;
    margin: 0 auto;
    text-align: center;
    /* transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1); */
}
.text-second-span {
    display: inline-block;
    opacity: 0;
    -webkit-filter: blur(4px);
            filter: blur(4px);
}
button {
    max-width: 15rem;
    margin-top: 2.5rem !important;
    font-size: x-large;
    color: #40b3ac;
    outline: #40b3ac;
}
button:hover {
    background-color: #40b3ac;
}
.employee-class {
    font-weight: 600;
}
.below-header-flex {
    margin: 20px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
}
.below-header-img {
    display: block;
}
.below-header-text {
}
.below-header-text span {
    display: block;
    font-size: 25px;
}
.below-header-text span.ex {
    font-size: 20px;
}

.form{
    color: white;
    max-width: 100%;
    margin: 0 auto;
    font-size: 1vw;
}
form .form-control:focus{
    border-color: rgb(205,212,218);
    box-shadow: none;
  }

button{
    margin-top: 1rem!important;
  }
button:hover, button:focus, button:active{
    background-color: #40B3AC!important;
    border: 1px #40B3AC!important;
    color: white!important;
    outline: none!important;
    box-shadow: none!important;
  }
  @media only screen and (max-width: 842px){
    .form{
    
      font-size: 3vw;
  }
  button{
    margin-top: 0;
    background-color: #40B3AC!important;
    border: 1px #40B3AC!important;
    color: white!important;
    outline: none!important;
  }
button:hover, button:focus, button:active{
    background-color: #092e2c!important;
    border: 1px #092e2c!important;
    color: white!important;
    outline: none!important;
    box-shadow: none!important;
  }
  }
.contact-section{
    max-width: 100%;
    font-size: 1vw;
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr; 
    grid-gap: 0px 54px; 
    gap: 0px 54px; 
    margin-top: 3em;
   
}
.contact-section-left{
  padding: 2rem;
}
.contact-section-right{
  padding: 2rem;

}
.contact-section-text{
    /* color: white ;
    font-size: 5rem; */
    
}
.contact-section-left div:nth-child(1){
    color: white ;
    font-size: 3vw;
    margin: 0;
    padding: 0;
}
.contact-section-left div:nth-child(2){
    color: white ;
    font-size: 4vw;
    font-weight: 600;
    line-height: 130%;
}
.contact-section-left div:nth-child(3){
    color: white ;
    font-size: 1.8vw;
    width: 80%;
   
}

@media only screen and (max-width: 842px){
    .contact-section{
        max-width: 100%;
        font-size: 1vw;
        display: grid; 
        grid-template-columns: 1fr ; 
        grid-template-rows: 2fr; 
        grid-gap: 0px 54px; 
        gap: 0px 54px; 
        margin-top: 3em;
    }
    .contact-section-left div:nth-child(3){
        color: white ;
        font-size: 2.6vw;
        width: 80%;
       
    }
   
}
.about-section {
    padding-top: 2rem;
    display: flex;
}
.contact-section-left {
    min-height: 100%;
    padding-top: 0 !important;
    padding-bottom: 0.5 !important;
}
.verif-span {
    color: #40b3ac;
    font-weight: 600;
}

.contact-section-left-mainTxt div:nth-child(1) {
    font-size: 1.4vw !important;
    /* padding: 0; */
    font-weight: 400;
    line-height: 135%;
}
.paragraph-two {
    font-size: 1.4vw !important;
    margin-top: 0.8em !important;
    font-weight: 400 !important;
    line-height: 135% !important;
}

.photo-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-gap: 7px 7px;
    gap: 7px 7px;
    grid-auto-flow: row;
    grid-template-areas:
        "photo1 photo4"
        "photo5 photo4"
        "photo3 photo2";
    color: white;
    padding: 1em;
    border: 1px white solid;
    max-height: 60%;
    max-width: 70%;
}

.photo-grid img {
    max-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.photo1 {
    grid-area: photo1;
    background-color: red;
    background: url(/static/media/team-1.d496e063.jpg) center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.photo2 {
    grid-area: photo2;
    background-color: blue;
    background: url(/static/media/team-2.0a57f6f6.jpg) center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.photo3 {
    grid-area: photo3;
    background-color: gray;
    background: url(/static/media/team-3.486ed145.jpg) center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.photo5 {
    grid-area: photo5;
    background-color: green;
    background: url(/static/media/team-5.96ca7e08.jpg) center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.photo4 {
    grid-area: photo4;
    background-color: black;
    background: url(/static/media/team-4.19eaf780.jpg) center center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media only screen and (max-width: 842px) {
    .about-section {
        padding: 1rem;
        display: flex;
        flex-direction: column;
    }
    .contact-section-left {
    }
    .photo-grid {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-gap: 7px 7px;
        gap: 7px 7px;
        grid-auto-flow: row;
        grid-template-areas:
            "photo1 photo4"
            "photo5 photo4"
            "photo3 photo2";
        color: white;
        padding: 1em;
        border: 1px white solid;
        max-width: 60%;
        margin-left: 2em;
    }
    .contact-section-left-mainTxt div:nth-child(1) {
        font-size: 2.5vw !important;
        /* padding: 0; */
        font-weight: 300;
        line-height: 150%;
    }
    .paragraph-two {
        font-size: 2.5vw !important;
        margin-top: 0.8em !important;
        font-weight: 300 !important;
        line-height: 135% !important;
    }
}

@media only screen and (max-width: 600px) {
    .photo-grid {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr fr fr;
        grid-gap: 7px 7px;
        gap: 7px 7px;
        grid-auto-flow: row;
        grid-template-areas:
            "photo4"
            "photo2"
            "";
        color: white;
        padding: 1em;
        border: 1px white solid;
        max-width: 70%;
        height: auto;
        margin-left: 2em;
    }
}

.form {
    color: white;
    max-width: 100%;
    margin: 0 auto;
    font-size: 1vw;
}

form .formControl:focus {
    border-color: rgb(205, 212, 218);
    box-shadow: none;
}

button {
    margin-top: 1rem !important;
}
button:hover,
button:focus,
button:active {
    background-color: #40b3ac !important;
    border: 1px #40b3ac !important;
    color: white !important;
    outline: none !important;
    box-shadow: none !important;
}
@media only screen and (max-width: 842px) {
    .form {
        font-size: 3vw;
    }
    button {
        margin-top: 0;
        background-color: #40b3ac !important;
        border: 1px #40b3ac !important;
        color: white !important;
        outline: none !important;
    }
    button:hover,
    button:focus,
    button:active {
        background-color: #092e2c !important;
        border: 1px #092e2c !important;
        color: white !important;
        outline: none !important;
        box-shadow: none !important;
    }
}

.btn-round {
    margin-left: 5px;
    border-radius: 50% !important;
    width: 2em;
    height: 2em;
}

.calculator-input {
    color: #40b3ac !important;
    font-size: 1.2em !important;
    padding-left: 0 !important;
}

.line-table-calculator {
    color: teal;
    height: 5px !important;
}

.title-calculator{
    color: #40b3ac;
    background-color: rgba(27, 27, 27, 0.61);
    padding: 10px 0 10px 0;
}
.title-calculator-first{
    font-size: 1.2em;
}
.title-calculator-footer{
    padding-top: 10px;
    text-align: center;
    font-size: 25px;
}
.total-cost-number{
    font-size: 1.2em;
}
.form {
    color: white;
    max-width: 100%;
    margin: 0 auto;
    font-size: 1vw;
}

form .formControl:focus {
    border-color: rgb(205, 212, 218);
    box-shadow: none;
}

button {
    margin-top: 1rem !important;
}
button:hover,
button:focus,
button:active {
    background-color: #40b3ac !important;
    border: 1px #40b3ac !important;
    color: white !important;
    outline: none !important;
    box-shadow: none !important;
}
@media only screen and (max-width: 842px) {
    .form {
        font-size: 3vw;
    }
    button {
        margin-top: 0;
        background-color: #40b3ac !important;
        border: 1px #40b3ac !important;
        color: white !important;
        outline: none !important;
    }
    button:hover,
    button:focus,
    button:active {
        background-color: #092e2c !important;
        border: 1px #092e2c !important;
        color: white !important;
        outline: none !important;
        box-shadow: none !important;
    }
}

.btn-round {
    margin-left: 5px;
    border-radius: 50% !important;
    width: 2em;
    height: 2em;
}

.calculator-input {
    color: #40b3ac !important;
    font-size: 1.2em !important;
    padding-left: 0 !important;
}

.line-table-calculator {
    color: teal;
    height: 5px !important;
}

.title-calculator{
    color: #40b3ac;
    padding: 10px 0 10px 0;
}

.btc-iframe{
    margin: 0 auto;
    display: block;
}
