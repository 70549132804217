.aiSection-title-section{
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1000%;
    padding: 2em;
    margin: 0 auto;
    font-size: 1vw;
}
.aiSection-title-section img{
  max-width: 5rem;
  margin-right: 0.5em;
}

.aiSection-section{
    min-height: 40rem;
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
}
.aiSection-section img{
   max-width:25%;
   margin: 1rem;
   border-radius: 10px;
   /* border: solid 1px rgb(231, 229, 229); */
   cursor: zoom-in!important;
   box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
.img-cursor{
  cursor: zoom-in;
}

@media only screen and (max-width: 842px){
  .aiSection-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.aiSection-section img{
  max-width:60%;
  margin: 1rem;
  border-radius: 10px;
  cursor: zoom-in!important;
}
}