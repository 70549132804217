.text-second {
    background-color: #f7f7f7;
    padding: 1.3em;
    color: black;
    font-size: 3.2vw;
    /* width: 100%; */
    min-height: 8em;
    margin: 0 auto;
    text-align: center;
    /* transform: scale(0.94);
    animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1); */
}
.text-second-span {
    display: inline-block;
    opacity: 0;
    filter: blur(4px);
}
button {
    max-width: 15rem;
    margin-top: 2.5rem !important;
    font-size: x-large;
    color: #40b3ac;
    outline: #40b3ac;
}
button:hover {
    background-color: #40b3ac;
}
.employee-class {
    font-weight: 600;
}
.below-header-flex {
    margin: 20px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
}
.below-header-img {
    display: block;
}
.below-header-text {
}
.below-header-text span {
    display: block;
    font-size: 25px;
}
.below-header-text span.ex {
    font-size: 20px;
}
