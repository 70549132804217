
.supporter-section{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15rem;
    background-color: #f7f7f7;
    overflow: hidden;
}
.supportItem img{
    width: 9rem;
    margin: 3rem;
}
.supporter-title-section{
    padding: 1rem;
    margin: 0 auto;
    color: #afaaaa;
    background-color: #ffffff;
}