.BuySection {
    min-height: 35rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}
.BuySection img {
    max-width: 25%;
    padding-bottom: 3rem;
    padding-left: 3rem;
}
.BuySection ul li {
    list-style-type: circle;
    font-size: 2vw;
    padding: 0.5rem;
}
.leftPart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-right: 5rem;
}
.leftPart button {
    width: 10rem;
    margin: 1rem 2rem;
    font-size: 1.4em;
    color: rgb(40, 135, 83);
    outline: rgb(40, 135, 83);
}
.leftPart button:hover {
    background-color: #40b3ac;
}

@media only screen and (max-width: 842px) {
    .BuySection {
        min-height: 25rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .BuySection img {
        display: none;
    }
    .BuySection ul li {
        font-size: 4vw;
    }
    .leftPart {
        margin-right: 0;
    }
}
